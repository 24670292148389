<template>
  <multiselect
    placeholder="Search persons in family tree"
    class="people-multiselect"
    :ref="peopleRef"
    :value="value"
    :options="familyTreePersonsSearchListState"
    :internalSearch="false"
    :clearOnSelect="true"
    :show-no-options="true"
    :show-no-results="true"
    :hide-selected="multiple"
    :multiple="multiple"
    :taggable="false"
    :loading="familyTreePersonsSearchListLoadingState"
    :disabled="disabled"
    selectLabel=""
    deselectLabel=""
    label="full_name"
    trackBy="object_id"
    openDirection="bottom"
    @select="peopleSelect"
    @search-change="peopleSearch"
    @close="emptyPeopleSearch"
    @remove="peopleRemove"
  >
    <close-icon
      v-if="isMultiselectOpened(peopleRef) && allowClear"
      slot="caret"
      slot-scope="props"
      @mousedown.prevent.stop="
        clearPeople();
        props.toggle();
      "
      class="caret-icon"
    ></close-icon>
    <account-icon
      v-else
      slot="caret"
      slot-scope="props"
      @mousedown.prevent.stop="props.toggle()"
      class="caret-icon"
    ></account-icon>
    <template v-slot:option="params">
      <span class="option-label">
        <span class="person-name">{{ params.option.full_name }}</span
        ><span class="family-tree-name" v-if="showTreeName && params.option.family_tree"
          >({{ params.option.family_tree.name }})</span
        >
      </span>
    </template>
    <template v-slot:singleLabel="params">
      <span class="option-label">
        <span class="person-name">{{ params.option.full_name }}</span
        ><span class="family-tree-name" v-if="showTreeName && params.option.family_tree"
          >({{ params.option.family_tree.name }})</span
        >
      </span>
    </template>
    <span slot="noOptions">Start typing to search...</span>
    <span slot="noResult">No person found.</span>
    <div slot="afterList" class="loading-after-list" v-if="familyTreePersonsSearchListLoadingState">
      <span>Searching...</span>
    </div>
  </multiselect>
</template>

<script>
import debounce from 'lodash/debounce';
import AccountIcon from 'vue-material-design-icons/Account';
import CloseIcon from 'vue-material-design-icons/Close';
import Multiselect from 'vue-multiselect';
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      peopleRef: 'people-select',
    };
  },
  props: {
    value: [Array, Object], // pass single object if multiple=false
    familyTreeId: {type: [String, Number], required: false}, // if not passed, search will be done in all family trees
    multiple: {type: Boolean, default: true},
    allowClear: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
  },
  computed: {
    ...mapGetters([
      'familyTreePersonsSearchListState',
      'familyTreePersonsSearchListMetaState',
      'familyTreePersonsSearchListLoadingState',
    ]),
    showTreeName() {
      const treeCount = this.familyTreePersonsSearchListMetaState.family_trees_count;
      return !this.familyTreeId && treeCount && treeCount > 1;
    },
  },
  methods: {
    isMultiselectOpened(multiselectRef) {
      return this.$refs[multiselectRef] && this.$refs[multiselectRef].isOpen;
    },
    peopleSelect(event) {
      this.$emit('select', event);
    },
    peopleRemove(event) {
      this.$emit('remove', event);
    },
    clearPeople(event) {
      this.$emit('clear', event);
    },
    peopleSearch(search) {
      this.emptyPeopleSearch();
      if (search) {
        this.$store.commit('setFamilyTreePersonsSearchListLoadingState', true);
      }
      this.fetchPeopleSearch(search);
    },
    fetchPeopleSearch: debounce(function (search) {
      if (search) {
        this.$store.dispatch('searchPersonsInFamilyTreeAction', {familyTreeId: this.familyTreeId, q: search});
      } else {
        this.emptyPeopleSearch();
        this.$store.commit('setFamilyTreePersonsSearchListLoadingState', false);
      }
    }, 500),
    emptyPeopleSearch() {
      this.$store.commit('setFamilyTreePersonsSearchListState', []);
    },
    autofocus() {
      this.$refs[this.peopleRef].$el.focus();
    },
  },
  components: {Multiselect, AccountIcon, CloseIcon},
};
</script>

<style lang="scss" scoped>
.material-design-icon__svg {
  color: #666;
}
.people-multiselect::v-deep {
  .option-label {
    .family-tree-name {
      margin-left: 5px;
      color: $supplemental-text-color;
    }
  }
  .multiselect__option--highlight {
    .family-tree-name {
      color: $pale-gold;
    }
  }
}
</style>
