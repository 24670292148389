<template>
  <multiselect
    :ref="dateRef"
    placeholder="Date"
    :value="value"
    :options="dateOptions"
    :preserveSearch="true"
    :internalSearch="false"
    :clearOnSelect="false"
    :hide-selected="false"
    :show-no-options="true"
    openDirection="bottom"
    label="label"
    trackBy="value"
    selectLabel=""
    deselectLabel="Selected"
    @open="dateOpen"
    @select="dateSelect"
    @search-change="dateSearch"
  >
    <close-icon
      v-if="isMultiselectOpened(dateRef)"
      slot="caret"
      slot-scope="props"
      @mousedown.prevent.stop="
        clearDate();
        props.toggle();
      "
      class="caret-icon"
    ></close-icon>
    <calendar-icon
      v-else
      slot="caret"
      slot-scope="props"
      @mousedown.prevent.stop="props.toggle()"
      class="caret-icon"
    ></calendar-icon>
    <span slot="noOptions">Input month, day or year</span>
    <span slot="noResult">Input month, day or year</span>
  </multiselect>
</template>

<script>
import moment from 'moment';
import CalendarIcon from 'vue-material-design-icons/Calendar';
import CloseIcon from 'vue-material-design-icons/Close';
import Multiselect from 'vue-multiselect';

export default {
  props: {
    value: Object, // {label, value} or null
  },
  data() {
    const inputOutputDateFormats = [
      // [input, output]
      ['YYYY', 'YYYY'],
      ['M YYYY', 'MMM YYYY'],
      ['MM YYYY', 'MMM YYYY'],
      ['MMM YYYY', 'MMM YYYY'],
      ['D M YY', 'DD MMM YYYY'],
      ['D M YYYY', 'DD MMM YYYY'],
      ['DD M YY', 'DD MMM YYYY'],
      ['DD M YYYY', 'DD MMM YYYY'],
      ['D MM YY', 'DD MMM YYYY'],
      ['D MM YYYY', 'DD MMM YYYY'],
      ['DD MM YY', 'DD MMM YYYY'],
      ['DD MM YYYY', 'DD MMM YYYY'],
      ['D MMM YY', 'DD MMM YYYY'],
      ['D MMM YYYY', 'DD MMM YYYY'],
      ['DD MMM YY', 'DD MMM YYYY'],
      ['DD MMM YYYY', 'DD MMM YYYY'],
      ['M D YY', 'DD MMM YYYY'],
      ['M D YYYY', 'DD MMM YYYY'],
      ['M DD YY', 'DD MMM YYYY'],
      ['M DD YYYY', 'DD MMM YYYY'],
      ['MM D YY', 'DD MMM YYYY'],
      ['MM D YYYY', 'DD MMM YYYY'],
      ['MM DD YY', 'DD MMM YYYY'],
      ['MM DD YYYY', 'DD MMM YYYY'],
      ['MMM D YY', 'DD MMM YYYY'],
      ['MMM D YYYY', 'DD MMM YYYY'],
      ['MMM DD YYYY', 'DD MMM YYYY'],
    ];
    return {
      inputOutputDateFormats: [
        ...inputOutputDateFormats,
        ...inputOutputDateFormats.map(array => [array[0].split(' ').join('/'), array[1]]),
        ...inputOutputDateFormats.map(array => [array[0].split(' ').join('.'), array[1]]),
      ],
      dateOptions: [],
      dateRef: 'date-select',
    };
  },
  methods: {
    isMultiselectOpened(multiselectRef) {
      return this.$refs[multiselectRef] && this.$refs[multiselectRef].isOpen;
    },
    dateOpen() {
      if (this.value && this.value.value) {
        this.$refs[this.dateRef].updateSearch(this.value.value);
      }
    },
    dateSelect(event) {
      this.$emit('select', event);
    },
    clearDate() {
      this.$emit('clear');
    },
    dateSearch(searchQuery) {
      this.dateOptions = [];
      searchQuery = searchQuery && searchQuery.trim();
      for (let inputOutputFormats of this.inputOutputDateFormats) {
        let result = moment(searchQuery, inputOutputFormats[0], true).format(inputOutputFormats[1]);
        if (result && result !== '' && result !== 'Invalid date' && !this.dateOptions.find(o => o.value === result)) {
          this.dateOptions.push({label: result, value: result});
        }
      }
    },
  },
  components: {Multiselect, CalendarIcon, CloseIcon},
};
</script>

<style lang="scss" scoped>
.material-design-icon__svg::v-deep {
  color: #666;
}
</style>
